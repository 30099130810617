import { h } from "vue";

export default ({ weightData }) => {

    // 计算某个字段的合并数
    function calcSpan(list = [], key) {
        list = list.reverse();
        list = list.map((item, index) => {
            // 计算大类列合并数
            item.rowspan = 1;
            item.colspan = 1;
            const pre = list[index - 1];
            if (pre && pre[key] === item[key]) {
                item.rowspan = pre.rowspan + 1;
                pre.rowspan = 0;
                pre.colspan = 0;
            }
            return item;
        });
        return list.reverse();
    }

    // 合并
    function mergeRows({ row, column }) {
        if (column.label === "大类") {
            return { rowspan: row.rowspan, colspan: row.colspan };
        } else {
            return { rowspan: 1, colspan: 1 };
        }
    }

    // 合计
    function getSummaries() {
        // 计算configValue的总和
        let total = 0;
        weightData.value.forEach((item) => {
            total += Number(item.configValue);
        });
        return ["", "总计", h("div", { style: { color: total > 100 ? "red" : "" } }, total)];
    }

    return { calcSpan, mergeRows, getSummaries }
}