<template>
    <div class="container">
        <div class="row_box"><h1 style="font-size: 20px">全局配置</h1></div>
        <!-- KPI权重设置 -->
        <div class="row_box">
            <div class="tit"><SmallTitle tit="KPI权重设置" /></div>
            <el-table :data="weightData" border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }" show-summary :summary-method="getSummaries" :span-method="mergeRows">
                <el-table-column prop="configCategory" label="大类" width="150" />
                <el-table-column prop="configName" label="统计值（得分）" />
                <el-table-column label="评分权重" width="150">
                    <template #default="scope">
                        <el-input type="number" v-model="scope.row.configValue" v-if="scope.row.enableMod === 'Y'"></el-input>
                        <span v-else>{{ scope.row.configValue }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- EGPM图表配置 -->
        <div class="row_box">
            <div class="tit"><SmallTitle tit="EGPM点位颜色区间设置" /></div>
            <el-table :data="colorData" border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }">
                <el-table-column prop="configCategory" label="稳定性" width="150" />
                <el-table-column prop="configName" label="显示颜色" align="center">
                    <template #default="scope">
                        <div class="color_box" :style="{ background: scope.row.configName }"></div>
                    </template>
                </el-table-column>
                <el-table-column label="稳定性得分区间" width="300">
                    <template #default="scope">
                        <el-input-number v-model="scope.row.wdMin" :min="0" :max="20" />
                        <span> - </span>
                        <el-input-number v-model="scope.row.wdMax" :min="0" :max="20" />
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="submit_box">
            <el-button type="primary" @click="saveConfig">保存配置</el-button>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { apiEgpmDictWeight, apiEgpmDictWeightSave } from "@/request/egpmBrandApi";
import withLoading from "@/utils/loading";
import useUtils from "./useUtils";

const router = useRouter();
const route = useRoute();
const reportId = route.query.reportId;
const weightData = ref([]); // 权重配置数据
const colorData = ref([]); // 颜色配置数据

// 获取配置
async function getConfig() {
    const { data } = await apiEgpmDictWeight({ reportConfigId: reportId });
    const list = data || [];
    // 权重配置数据
    weightData.value = calcSpan(
        list.filter((item) => item.configType === "YY_WEIGHT"),
        "configCategory"
    );
    // 颜色配置数据
    colorData.value = list.filter((item) => item.configType === "YY_COLOR_RANGE");
    colorData.value.map((item) => {
        const rang = item.configValue.split("-");
        item.wdMin = Number(rang[0] || 0);
        item.wdMax = Number(rang[1] || 0);
    });
}

// 保存验证
async function saveConfig() {
    // 验证权重总和是否等于100
    let total = 0;
    weightData.value.forEach((item) => {
        total += Number(item.configValue);
    });
    if (total !== 100) {
        ElMessage.error("评分权重总计必须等于100");
        return;
    }
    // 稳定性得分区间
    if (colorData.value.some((item) => item.wdMin > item.wdMax)) {
        ElMessage.error("稳定性得分区间最小值不能大于最大值");
        return;
    }
    let params = [...weightData.value, ...colorData.value];
    params = params.map((item) => {
        if (item.configType === "YY_COLOR_RANGE") {
            item.configValue = item.wdMin + "-" + item.wdMax;
        }
        return item;
    });
    handleSubmit(params);
}

// 保存请求
async function handleSubmit(params) {
    const { code } = await withLoading(apiEgpmDictWeightSave)({ config: params, reportConfigId: reportId });
    if (code !== "0") return;
    ElMessageBox.confirm("保存成功!", "提示", {
        confirmButtonText: "返回任务列表",
        cancelButtonText: "继续编辑",
    }).then(() => {
        router.replace({
            path: "/egpm/brand",
            query: {
                reportId: route.query.reportId,
            },
        });
    });
}

// 合计&单元格合并
const { calcSpan, mergeRows, getSummaries } = useUtils({ weightData });

(() => {
    getConfig();
})();
</script>

<style lang="scss" scoped>
.container {
    padding: 0 40px 40px 40px;
    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;
        .tit {
            padding-bottom: 12px;
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 15px;
        }
        .el-select {
            width: 100%;
        }
        .table_form {
            table {
                width: 100%;
                border-collapse: collapse;

                th,
                td {
                    border: 1px solid #ccc;
                    text-align: center;
                    padding: 6px;
                    color: #333;

                    &.back {
                        background-color: #f2f2f2;
                    }

                    &.text_red {
                        color: red;
                    }
                    .el-input {
                        width: 120px;
                    }
                }

                th {
                    background-color: #f2f2f2;
                }
            }
        }
        .color_box {
            width: 100px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid #ccc;
            margin: 0 auto;
        }
    }
    .submit_box {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
}
</style>
